body {
  background: $secondary-color-light-light;
}

main {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: right / cover no-repeat url(../img/Main_bg_3.jpg);
  height: 100vh;
  width: 100%;
  z-index: 1;

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(200deg, $secondary-color-light, white);
    opacity: 0.7;
    height: 100%;
    width: 100%;
    z-index: -3;
  }
}
.banner {
  display: block;
  position: relative;
  margin: 0;
  padding: 1em 1em 1em 10em;
  top: 55%;

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    height: 100%;
    width: 100%;
    z-index: -3;
    opacity: 0.7;
  }
}

h1 {
  font-family: $header-font;
  color: $dark-gray;
  font-size: 2em;
  text-transform: uppercase;
  margin: 0;
  box-shadow: $secondary-color 0 3px;
  line-height: 1.1em;
  padding: 0.3em 0;
  text-shadow: 2px 2px 5px;
}
h3 {
  font-family: $body-font;
  color: $dark-gray;
  margin: 5px 0;
}

.two {
  font-style: italic;
  color: $dark-gray;
  font-family: $header-font;
  line-height: 1em;
  font-size: 1em;
}

//smaller screens
@media screen and (max-width: 768px) {
  main {
    position: relative;
    .banner {
      display: block;
      position: relative;
      margin: 0;
      padding: 1em 1em 1em 2em;
      top: 55%;
    }
    h1 {
      font-family: $header-font;
      font-size: 2.2em;
      text-transform: uppercase;
      margin: 0;
      box-shadow: $secondary-color 0 3px;
    }
    h3 {
      font-family: $body-font;
      margin: 5px 0;
      line-height: 1.2em;
    }
    .two {
      line-height: 1em;
    }
  }
}
