.all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
}

.page {
  width: 60%;
  max-width: 900px;
  margin: 20px auto 0 auto;
}

h2 {
  font-family: $header-font;
  font-style: italic;
  color: $dark-gray;
  padding: 3px 3px 3px 1em;
  width: 7em;
  font-size: 2em;
  margin: 0 1em;
  box-shadow: 0 3px $secondary-color;
  //box-shadow: inset 2px -2px 2px $primary-color;
}

.info p {
  font-family: $body-font;
  color: $dark-gray;
  font-size: 1.1em;
  line-height: 1.8em;
}

contact_wrapper {
  display: flex;
}

#contact_wrapper p {
  border-left: $secondary-color 2px solid;
  border-bottom: $secondary-color 2px solid;
  padding: 1em;
  text-decoration: none;
  color: $dark-gray;
  max-width: 50%;
}

.list {
  display: flex;
  flex-flow: column;
  list-style: inside;
  font-family: $body-font;
  color: $dark-gray;
  font-size: 1.05em;
  text-transform: capitalize;
  line-height: 1.5em;
}

// smaller screens
@media screen and (max-width: 768px) {
  .page {
    width: 90%;
    margin: 20px auto 0 auto;
  }
}
h2 {
  font-size: 1.5em;
}

.info .body_text {
  font-size: 1em;
}
