form {
  margin: 0 auto;
  padding: 0 2em 2em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  label {
    font-family: $body-font;
    text-transform: uppercase;
    display: flex;
    flex-flow: column;
    width: 70%;
    padding: 2em 0 0 0;
  }

  .form_input_area {
    margin: 0.5em 0 0 0;
    background: $light-light-gray;
    border: solid 1px $dark-gray;
    box-shadow: inset 1px 1px 3px;
    padding: 10px;
    border-radius: 2px;
  }

  button {
    padding: 0;
    border: none;
    background: none;
    background: $secondary-color;
    padding: 0.7em;
    width: 30%;
    margin-bottom: 0.6em;
    border-radius: 10px;
    box-shadow: inset 1px 1px 3px $dark-gray;
    transition: transform 0.2s, background 0.5s, box-shadow 0.5s;
    text-transform: uppercase;
    font-family: $body-font;
    color: $light-gray;
    margin: 1em 0 0 0;
    cursor: pointer;

    &:hover {
      background: $secondary-color-light-opaque;
      transform: translateY(0.2em);

      &:active {
        transform: translateY(-0.2em);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  form {
    label {
      width: 80%;
    }
  }
}
