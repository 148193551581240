#closeIcon {
    display: none;
}

ul {
    margin: 0;
    position: relative;
    display: flex;
    justify-content: flex-end;
    list-style: none;
}

ul a {
    display: flex;
    margin: 2em 3em 0 0;
    background: $dark-gray;
    padding: 0.5em 1.5em;
    box-shadow: 0 3px $secondary-color;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-decoration: none;
    font-size: 0.8em;
    font-family: $body-font;
    font-weight: 700;
    color: $light-gray;
    transition: background 1s, color 0.5s, box-shadow 0.5s, transform 0.2s;

    &:hover {
        background: $light-gray;
        color: $dark-gray;
        box-shadow: 0 3px gold;
        transform: translateY(0.2em);
    }
}

#logo_top {
    fill: $dark-gray;
}
.st1 {
    fill: $light-gray;
}

#logo_top {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 6em;
    margin: -18px 0 0 5em;
    transition: all 0.5s ease-in;

    &:hover,
    &:active {
        transform: translateY(1em) scale(1.1);
    }
}

@media screen and (max-width: 768px) {
    #menuToggle {
        display: flex;
        flex-flow: column;
        position: fixed;
        top: 2em;
        right: 2em;

        z-index: 1;

        -webkit-user-select: none;
        user-select: none;
    }

    #menuToggle input {
        display: flex;
        width: 40px;
        height: 32px;
        position: fixed;
        top: 2em;
        right: 2em;

        cursor: pointer;

        opacity: 0; /* hide this */
        z-index: 2; /* and place it over the hamburger */

        -webkit-touch-callout: none;
    }

    #logo_top {
        opacity: 1;
        width: 5em;
        margin: -25px 0 0 3em;
    }

    /*
 * Just a quick hamburger
 */
    #menuToggle span {
        display: block;
        width: 33px;
        height: 6px;
        margin-bottom: 5px;
        position: relative;

        background: $secondary-color;
        border-radius: 3px;

        z-index: 1;

        transform-origin: 4px 0px;

        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }

    #menuToggle span:first-child {
        transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2) {
        transform-origin: 0% 100%;
    }

    /*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
    #menuToggle input:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(3px, 2px);
        background: $dark-gray;
    }

    /*
 * But let's hide the middle one.
 */
    #menuToggle input:checked ~ span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    /*
 * Ohyeah and the last one should go the other direction
 */
    #menuToggle input:checked ~ span:nth-last-child(2) {
        transform: rotate(-45deg) translate(1px, 2px);
    }

    /* This is the pop out menu
 * Make this absolute positioned
 * at the top right of the screen
 */
    #menu {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        height: 120vh;
        margin: -5em 0 0 5em;

        width: 20em;
        padding: 8em 0;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        /* to stop flickering of text in safari */

        transform-origin: 0% 0%;
        /* this part affects final destination of menu */
        transform: translate(0, 0);

        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }

    #menu li {
        align-items: flex-start;
        justify-content: center;
        display: flex;
        font-size: 22px;
        width: 100%;
    }

    #menu a {
        display: flex;
        border-top: 2px $secondary-color solid;
        margin: 0;
        width: 100%;
        padding: 1em 3em;
        text-transform: uppercase;
        box-shadow: 0 3px $secondary-color;
        font-family: $body-font;
        color: $light-gray;
        transition: background 0.5s, transform 0.2s, color 0.5s,
            box-shadow 0.5s border 0.2s;

        &:hover,
        &:active {
            background: $light-gray;
            color: $dark-gray;
            box-shadow: 0 3px gold;
            transform: translateY(0.2em);
            border: none;
        }
    }

    /*
 * And let's slide it in from the left
 */
    #menuToggle input:checked ~ ul {
        transform: translate(-100%, 0);
    }
}
